import * as React from "react";
import { ReactElement } from "react";
import PropTypes from "prop-types";
import {
  ChoicesContextProvider,
  InputProps,
  ListContextProvider,
  ResourceContextProvider,
  useListController,
  useReferenceArrayInputController,
  UseReferenceArrayInputParams,
} from "ra-core";
import { Box, Card, InputLabel } from "@mui/material";

/**
 * An Input component for fields containing a list of references to another resource.
 * Useful for 'hasMany' relationship.
 *
 * @example
 * The post object has many tags, so the post resource looks like:
 * {
 *    id: 1234,
 *    tag_ids: [ "1", "23", "4" ]
 * }
 *
 * MyReferenceTableInput component fetches the current resources (using
 * `dataProvider.getMany()`) as well as possible resources (using
 * `dataProvider.getList()`) in the reference endpoint. It then
 * delegates rendering to its child component, to which it makes the possible
 * choices available through the ChoicesContext.
 *
 * Use it with a selector component as child, like `<SelectArrayInput>`
 * or <CheckboxGroupInput>.
 *
 * @example
 * export const PostEdit = () => (
 *     <Edit>
 *         <SimpleForm>
 *             <MyReferenceTableInput source="tag_ids" reference="tags">
 *                 <SelectArrayInput optionText="name" />
 *             </MyReferenceTableInput>
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * By default, restricts the possible values to 25. You can extend this limit
 * by setting the `perPage` prop.
 *
 * @example
 * <MyReferenceTableInput
 *      source="tag_ids"
 *      reference="tags"
 *      perPage={100}>
 *     <SelectArrayInput optionText="name" />
 * </MyReferenceTableInput>
 *
 * By default, orders the possible values by id desc. You can change this order
 * by setting the `sort` prop (an object with `field` and `order` properties).
 *
 * @example
 * <MyReferenceTableInput
 *      source="tag_ids"
 *      reference="tags"
 *      sort={{ field: 'name', order: 'ASC' }}>
 *     <SelectArrayInput optionText="name" />
 * </MyReferenceTableInput>
 *
 * Also, you can filter the query used to populate the possible values. Use the
 * `filter` prop for that.
 *
 * @example
 * <MyReferenceTableInput
 *      source="tag_ids"
 *      reference="tags"
 *      filter={{ is_public: true }}>
 *     <SelectArrayInput optionText="name" />
 * </MyReferenceTableInput>
 *
 * The enclosed component may filter results. MyReferenceTableInput create a ChoicesContext which provides
 * a `setFilters` function. You can call this function to filter the results.
 */
const defaultSort = { field: "id", order: "DESC" as const };

export const MyReferenceTableInput = (props: MyReferenceTableInputProps) => {
  const {
    children,
    debounce,
    filter = defaultFilter,
    perPage = 1000,
    label,
    reference,
    sort = defaultSort,
  } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<MyReferenceTableInput> only accepts a single child (like <Datagrid>)"
    );
  }
  const listContext = useListController({
    debounce,
    filter,
    sort,
    resource: reference,
    perPage,
  });
  const controllerProps = useReferenceArrayInputController({
    ...props,
    sort,
    enableGetChoices: () => false,
    filter,
  });
  return (
    <ResourceContextProvider value={reference}>
      <ChoicesContextProvider value={controllerProps}>
        <ListContextProvider value={listContext}>
          <Box p={"8px 0"}>
            <InputLabel sx={{ m: "0 0 8px 0" }}>{label}:</InputLabel>
            <Box display={"grid"}>
              <Card>{children}</Card>
            </Box>
          </Box>
        </ListContextProvider>
      </ChoicesContextProvider>
    </ResourceContextProvider>
  );
};

MyReferenceTableInput.propTypes = {
  children: PropTypes.element,
  filter: PropTypes.object,
  label: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(["ASC", "DESC"]),
  }),
  source: PropTypes.string,
};

const defaultFilter = {};

export interface MyReferenceTableInputProps
  extends InputProps,
    UseReferenceArrayInputParams {
  children?: ReactElement;
  label?: string;

  [key: string]: any;
}
