import { MyField } from "../../components/types";
import { FunctionField, TextField, WrapperField } from "react-admin";
import { FormDataConsumer, RaRecord } from "ra-core";
import { base, BaseArgs } from "./base";
import { ReactElement, ReactNode } from "react";

export interface DependencyArgs extends BaseArgs {
  label: string;
  source: string;
  editRender: ({
    formData,
    rest,
  }: {
    formData: RaRecord;
    rest: any;
  }) => ReactElement<any, any> | undefined;
  render: (record: any, source?: string) => ReactNode;
}

const defaultArgs: Partial<DependencyArgs> = {};

export function dependency(args: DependencyArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FunctionField,
      props: {
        render: args.render,
      },
      editComponent: FormDataConsumer,
      editProps: {
        children: args.editRender,
      },
    },
    field
  );
}
