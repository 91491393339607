import VisitorIcon from "@mui/icons-material/People";
import * as React from "react";
import { MyField } from "../components/types";
import { Field } from "../utils/Field";
import { transform } from "../utils/imageTransform";
import { MyList } from "../components/core/MyList";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";

const fields: MyField[] = [
  Field.objectId(),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.text({
    label: "简介",
    source: "desc",
    sortable: false,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "修改时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <MyCreate fields={fields} transform={transform} />;
  },
  edit: () => {
    return <MyEdit fields={fields} transform={transform} />;
  },
  icon: <VisitorIcon />,
};

export default resource;
