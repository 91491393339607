import { NumberInput, NumberInputProps } from "react-admin";
import { InputAdornment } from "@mui/material";
import React from "react";
import { AttachMoney, Money } from "@mui/icons-material";

export interface AmountInputProps extends NumberInputProps {}

export function AmountInput(props: AmountInputProps) {
  return (
    <NumberInput
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <AttachMoney />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
