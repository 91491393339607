import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form } from "react-admin";

interface ConfirmFormDialogProps {
  isOpen: boolean;
  title?: string;
  form?: React.ReactNode;
  onClose: (confirmed: boolean) => void;
  onSubmit: (form: any) => Promise<void>;
}

const ConfirmFormDialog: React.FC<ConfirmFormDialogProps> = ({
  isOpen,
  title,
  form,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      <Form onSubmit={onSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent sx={{ minWidth: "320px" }}>{form}</DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            取消
          </Button>
          <Button color="primary" autoFocus type={"submit"}>
            确定
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ConfirmFormDialog;
