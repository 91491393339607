import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { RiApps2Fill } from "@remixicon/react";

const fields: MyField[] = [
  Field.objectId(),
  Field.text({
    label: "名称",
    source: "name",
    isFilter: true,
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    isFilter: false,
  }),
  Field.img({
    label: "图标",
    isRequired: true,
    source: "icon",
  }),
  Field.richText({
    label: "介绍",
    source: "desc",
    fullWidth: true,
    isFilter: false,
  }),
  Field.text({
    label: "下载web地址",
    source: "downPage",
    isFilter: true,
  }),
  Field.imgs({
    minWidth: 180,
    label: "截图",
    isRequired: true,
    source: "screenshots",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <RiApps2Fill />,
};

export default resource;
