import { MyField } from "../../components/types";
import { ImageField } from "react-admin";
import * as React from "react";
import { MyImageInput } from "../../components/MyImageInput";
import { base, BaseArgs } from "./base";
import { TextArgs } from "./text";
import { MyImageSwipersValueInput } from "../../components/MyImageSwipersValueInput";

export interface ImgsArgs extends BaseArgs {}

const defaultArgs: Partial<TextArgs> = {
  isRequired: false,
  sortable: false,
  isFilter: false,
};

export function imgs(args: ImgsArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: ImageField,
      props: {
        source: `${args.source}`,
        src: `url`,
        sx: {
          "& .RaImageField-list": {
            p: 0,
            m: 0,
          },
          "& img": {
            maxWidth: "50px !important",
            maxHeight: "30px !important",
            margin: "0 !important",
            objectFit: "contain",
          },
          "&": {
            maxHeight: 30,
            objectFit: "contain",
            overflow: "hidden",
          },
        },
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
      editComponent: MyImageSwipersValueInput,
      editProps: {
        accept: "image/*",
      },
    },
    field
  );
}
