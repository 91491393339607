import { Box } from "@mui/material";
import {
  DateField,
  DeleteWithConfirmButton,
  SelectField,
  SelectInput,
  TextField,
} from "react-admin";
import { MyList } from "../components/core/MyList";
import { MyField } from "../components/types";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Field } from "../utils/Field";
import { ResourceType } from "../components/core/MyResource";
import ObjectIdField from "../components/ObjectIdField";

const fields: MyField[] = [
  {
    label: "ID",
    component: ObjectIdField,
    isEdit: true,
    sortable: true,
    isUpdate: false,
    editComponent: Box,
    editProps: {
      children: (
        <>
          ID:
          <ObjectIdField fullWidth />
        </>
      ),
    },
  },
  {
    label: "类型",
    source: "type",
    sortable: false,
    isPrimary: true,
    isFilter: true,
    component: SelectField,
    editComponent: SelectInput,
  },
  Field.localRef({
    label: "会员",
    source: "userId",
    isFilter: false,
    reference: (r: any) => {
      if (r.type === "user") {
        return "users";
      } else {
        return "admins";
      }
    },
  }),
  // {
  //   label: "会员",
  //   source: "userId",
  //   sortable: false,
  //   isPrimary: true,
  //   isFilter: true,
  //   component: LocalReferenceField,
  //   props: {
  //     source: "userId",
  //     fullWidth: true,
  //     valueSource: "name",
  //     reference: (r: any) => {
  //       if (r.type === "user") {
  //         return "users";
  //       } else {
  //         return "admins";
  //       }
  //     },
  //   },
  //   filterProps: {
  //     alwaysOn: true,
  //     clearAlwaysVisible: true,
  //     resettable: true,
  //   },
  //   editComponent: TextInput,
  // },
  {
    label: "IP",
    source: "client",
    sortable: true,
    isPrimary: true,
    isFilter: true,
    component: TextField,
    props: {
      fullWidth: true,
    },
  },
  {
    label: "登录时间",
    sortable: true,
    source: "createdDate",
    component: DateField,
    props: {
      showTime: true,
    },
  },
  {
    label: "离线时间",
    source: "logoutDate",
    component: DateField,
    props: {
      showTime: true,
    },
  },
];

const resource: ResourceType = {
  hasDelete: false,
  list: () => {
    return (
      <MyList fields={fields} rowActions={[<DeleteWithConfirmButton />]} />
    );
  },
  icon: <ReorderIcon />,
};

export default resource;
