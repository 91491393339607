import VisitorIcon from "@mui/icons-material/People";
import {
  CreateButton,
  ExportButton,
  FilterButton,
  maxLength,
  minLength,
  RefreshButton,
  required,
  TopToolbar,
} from "react-admin";
import * as React from "react";
import { MyField } from "../components/types";
import omit from "lodash/omit";
import { convertFileToBase64 } from "../utils/fileToBase64";
import { Field } from "../utils/Field";
import { FaceValuesTypeData, StatusTypeData } from "../model/Core";
import { toString } from "../utils/amountUtils";
import { MyList } from "../components/core/MyList";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import {
  MyResource,
  MyResourceProps,
  ResourceType,
} from "../components/core/MyResource";

const fields: MyField[] = [
  Field.objectId(),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeData,
  }),
  Field.maxText({
    label: "ApiUrl",
    source: "apiUrl",
  }),
  Field.maxText({
    label: "apiKey",
    source: "apiKey",
  }),
  Field.maxText({
    label: "通道",
    source: "apiChannel",
  }),
  Field.autocompleteArray({
    label: "面值",
    source: "faceValues",
  }),
  Field.maxText({
    label: "secretKey",
    source: "apiSecretKey",
    isList: false,
  }),
  Field.dateTime({
    label: "修改时间",
    source: "updateDate",
  }),
];

let transform = async (data: any) => {
  if (data?.key?.rawFile) {
    const key = await convertFileToBase64(data.key.rawFile);
    return { ...data, key };
  }
  if (data.key && data.key.src) {
    return { ...data, key: data.key.src };
  }
  return { ...omit(data, ["key"]), key: "" };
};

const resource: ResourceType = {
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <MyCreate fields={fields} transform={transform} />;
  },
  edit: () => {
    return <MyEdit fields={fields} transform={transform} />;
  },
  icon: <VisitorIcon />,
};

export default resource;
