import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import qs from "qs";
import { formatDateTime } from "../utils/formatDateTime";

export const GroupMemberApi = {
  inviteUserToGroup: async (
    groupID: string,
    invitedUserIDs: string[],
    reason: string
  ): Promise<void> => {
    await fetchJson(`${API_URL}/groupMembers/inviteUserToGroup`, {
      method: "POST",
      body: qs.stringify(
        {
          groupID,
          invitedUserIDs,
          reason,
        },
        {
          allowDots: true,
          skipNulls: true,
          serializeDate: (d: Date) => formatDateTime(d),
        }
      ),
    });
  },
  kickGroup: async (
    groupID: string,
    kickedUserIDs: string[],
    reason: string
  ): Promise<void> => {
    await fetchJson(`${API_URL}/groupMembers/kickGroup`, {
      method: "POST",
      body: qs.stringify(
        {
          groupID,
          kickedUserIDs,
          reason,
        },
        {
          allowDots: true,
          skipNulls: true,
          serializeDate: (d: Date) => formatDateTime(d),
        }
      ),
    });
  },
  muteGroupMember: async (id: string, seconds: number): Promise<void> => {
    await fetchJson(
      `${API_URL}/groupMembers/muteGroupMember?id=${id}&seconds=${seconds}`,
      {
        method: "POST",
      }
    );
  },
  cancelMuteGroupMember: async (id: string): Promise<void> => {
    await fetchJson(`${API_URL}/groupMembers/cancelMuteGroupMember?id=${id}`, {
      method: "POST",
    });
  },
};
