import { NumberInput, NumberInputProps } from "react-admin";
import { InputAdornment } from "@mui/material";
import React from "react";
import { PercentIcon } from "../icons/PercentIcon";
import { format, parse } from "../utils/percent";

export interface PercentInputProps extends NumberInputProps {}

export function PercentInput(props: PercentInputProps) {
  return (
    <NumberInput
      format={(v) => format(v)}
      parse={(v) => parse(v)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PercentIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
