import { MyField } from "../../components/types";
import { base, BaseArgs } from "./base";
import { BooleanField, BooleanInput } from "react-admin";

export interface BooleanArgs extends BaseArgs {}

const defaultArgs: Partial<BooleanArgs> = {
  isRequired: false,
  fullWidth: false,
  isFilter: false,
  sortable: true,
};

export function boolean(args: BooleanArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: BooleanField,
      editComponent: BooleanInput,
    },
    field
  );
}
