import React from "react";
import { MyLocalesValueInput } from "../../components/MyLocalesValueInput";
import { MyImageInput } from "../../components/MyImageInput";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
} from "react-admin";
import get from "lodash/get";

export const SiteSetting: React.FC = (props) => {
  let optionText = (choice?: any) => {
    let value = get(choice, "symbol");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };
  return (
    <>
      <TextInput label={"文件服务地址"} source={"setting.fileUrl"} />
      <MyImageInput label={"网站logo"} source={"setting.logoUrl"} />
      <MyImageInput
        label={"网站logoFavicon"}
        source={"setting.logoFaviconUrl"}
      />
      <MyImageInput label={"网站logoTitle"} source={"setting.logoTitleUrl"} />
      <MyImageInput label={"默认用户头像"} source={"setting.defaultUserHead"} />
      <TextInput label="网站名称" source="setting.name" />
      <MyLocalesValueInput label={"网站标题"} source={"setting.title"} />
      <MyLocalesValueInput label={"网站介绍"} source={"setting.description"} />
      <ReferenceInput
        label={"默认币种"}
        source={"setting.coinSymbol"}
        reference={"coins"}
      >
        <AutocompleteInput
          validate={[required()]}
          label={`默认币种`}
          optionText={optionText}
          name={"setting.coinSymbol"}
        />
      </ReferenceInput>
    </>
  );
};
