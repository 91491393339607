import { MyDatagrid } from "./MyDatagrid";
import * as React from "react";
import { MyField } from "../types";
import { CommandProps } from "../command/Command";
import { useMemo } from "react";
import { AccountStorage } from "../../AccountStorage";
import { defaultCommandProps } from "../../model/command";
import {
  ResourceConfig,
  ResourceConfigProvider,
} from "../../hook/ResourceConfigContext";
import { useAuthState, useResourceDefinition } from "react-admin";
import { DefaultCommands, MyResource } from "./MyResource";
import { Route } from "react-router-dom";

export interface MyTableProps {
  fields: MyField<any>[];
  hasDelete: boolean;
  commands?: React.ReactElement<CommandProps>[];
}

MyRefTable.defaultProps = {
  commands: [],
  hasDelete: true,
};

export function MyRefTable({ fields, commands, hasDelete }: MyTableProps) {
  const { isLoading } = useAuthState();
  const resourceDefinition = useResourceDefinition();
  const name = resourceDefinition.name;

  const permission = useMemo(() => {
    return {
      create: AccountStorage.hasModule(name + ".create"),
      delete: AccountStorage.hasModule(name + ".delete"),
      update: AccountStorage.hasModule(name + ".update"),
      show: AccountStorage.hasModule(name + ".show"),
    };
  }, [isLoading, name]);

  const resourceConfig = useMemo(() => {
    return {
      commands: commands?.map((command) => ({
        ...defaultCommandProps,
        ...command,
      })),
      resource: name,
      hasDelete: hasDelete,
    } as ResourceConfig;
  }, [fields, commands, name]);

  return (
    <ResourceConfigProvider value={resourceConfig}>
      {permission.show && <MyDatagrid fields={fields} commands={commands} />}
    </ResourceConfigProvider>
  );
}
