// ConfirmDialogContext.tsx
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import ConfirmDialog from "../components/core/ConfirmDialog";
import ConfirmFormDialog from "../components/core/ConfirmFormDialog";

interface ConfirmDialogContextType {
  requestConfirm: (options: ConfirmOptions) => Promise<boolean>;
  requestConfirmForm: (options: ConfirmOptions) => Promise<any>;
}

interface ConfirmOptions {
  title?: string;
  description?: React.ReactNode;
  form?: React.ReactNode;
}

const ConfirmDialogContext = createContext<
  ConfirmDialogContextType | undefined
>(undefined);

export const useConfirm = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmDialogProvider");
  }
  return context.requestConfirm;
};

export const useConfirmForm = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmDialogProvider");
  }
  return context.requestConfirmForm;
};

type HandleType = (value: boolean) => void;

export const ConfirmDialogProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [options, setOptions] = useState<ConfirmOptions | null>(null);
  const [resolveReject, setResolveReject] = useState<HandleType | undefined>(
    undefined
  );

  const requestConfirm = useCallback((options: ConfirmOptions) => {
    setIsOpen(true);
    setIsForm(false);
    setOptions(options);
    return new Promise<boolean | any>((resolve) => {
      setResolveReject(() => (value: boolean) => {
        resolve(value);
      });
    });
  }, []);

  const requestConfirmForm = useCallback((options: ConfirmOptions) => {
    setIsOpen(true);
    setIsForm(true);
    setOptions(options);
    return new Promise<boolean | any>((resolve) => {
      setResolveReject(() => (value: any) => {
        resolve(value);
      });
    });
  }, []);

  const handleClose = useCallback(
    (confirmed: boolean) => {
      setIsOpen(false);
      resolveReject?.(confirmed);
    },
    [resolveReject]
  );

  const handleSubmit = useCallback(
    async (form: any) => {
      setIsOpen(false);
      resolveReject?.(form);
    },
    [resolveReject]
  );

  return (
    <ConfirmDialogContext.Provider
      value={{ requestConfirm, requestConfirmForm }}
    >
      {children}
      {isOpen &&
        options &&
        (!isForm ? (
          <ConfirmDialog
            isOpen={isOpen}
            title={options.title}
            description={options.description}
            onClose={handleClose}
          />
        ) : (
          <ConfirmFormDialog
            isOpen={isOpen}
            title={options.title}
            form={options.form}
            onClose={handleClose}
            onSubmit={handleSubmit}
          />
        ))}
    </ConfirmDialogContext.Provider>
  );
};
