import React from "react";
import { ReferenceManyField, TabbedShowLayout } from "react-admin";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { MyField } from "../../components/types";

export function GroupShow({ fields }: { fields: MyField<any>[] }) {
  return (
    <MyShow>
      <TabbedShowLayout value={0}>
        <TabbedShowLayout.Tab label="群详细信息">
          <MyShowFields fields={fields} />
        </TabbedShowLayout.Tab>
        {/*<TabbedShowLayout.Tab label="钱包"></TabbedShowLayout.Tab>*/}
        {/*<TabbedShowLayout.Tab*/}
        {/*  label="会员记录"*/}
        {/*  path="userTypeOpRecords"*/}
        {/*></TabbedShowLayout.Tab>*/}
        {/*<TabbedShowLayout.Tab*/}
        {/*  label="帐变"*/}
        {/*  path="userWalletLog"*/}
        {/*></TabbedShowLayout.Tab>*/}
      </TabbedShowLayout>
    </MyShow>
  );
}
