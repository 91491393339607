import React, { useContext, useState } from "react";
import { MenuItem } from "../model/menu";

export type MenuInfo = {
  mainMenuName?: string;
  childMenu?: MenuItem;
  root: MenuItem[];
  updateLocation: (location: string) => void;
};

export const MenuContext = React.createContext<MenuInfo>({
  mainMenuName: undefined,
  childMenu: undefined,
  root: [],
  updateLocation: () => {
    throw new Error("init error");
  },
});

export function createMenuContext(root: MenuItem[]) {
  const menuMap = new Map<string, MenuItem>();
  const lv1Map = new Map<string, MenuItem>();

  root.forEach((item) => {
    lv1Map.set(item.name, item);

    item.children?.forEach((child) => {
      menuMap.set(child.href!, item);
    });
  });

  const Provider = ({ children }: { children: React.ReactNode }) => {
    const [selectName, setSelectName] = useState<string>();
    const [childMenu, setChildMenu] = useState<MenuItem>();

    const value: MenuInfo = {
      mainMenuName: selectName,
      childMenu: childMenu,
      root,
      updateLocation,
    };

    function updateLocation(location: string) {
      let locations = location.split("/");
      let pathFirst = menuMap.get("/" + locations?.[1]);
      if (!pathFirst) {
        menuMap.forEach((value, key) => {
          if (location.startsWith(key)) {
            pathFirst = value;
          }
        });
      }
      setSelectName(pathFirst?.name);
      setChildMenu(pathFirst);
    }

    return (
      <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
    );
  };

  return { Provider };
}

export const useUpdateLocation = (): MenuInfo["updateLocation"] => {
  const context = useContext(MenuContext);
  return context.updateLocation;
};

export const useChildMenu = (): MenuItem | undefined => {
  const context = useContext(MenuContext);
  return context.childMenu;
};

export const useMainMenuName = (): string | undefined => {
  const context = useContext(MenuContext);
  return context.mainMenuName;
};

export const useMenus = (): MenuItem[] => {
  const context = useContext(MenuContext);
  return context.root;
};
