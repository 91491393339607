import * as React from "react";
import { MouseEvent, useCallback } from "react";
import { Sync } from "@mui/icons-material";
import { Button, useDataProvider } from "react-admin";
import { menuItems } from "../../MenuData";
import { LoadingButton } from "../../components/LoadingButton";

export interface UploadMenuProps {}

export function UploadMenuButton(props: UploadMenuProps) {
  let dataProvider = useDataProvider();
  const handleClick = useCallback(async () => {
    const sysModule: any[] = [];
    menuItems.forEach((item) => {
      sysModule.push({
        id: item.code,
        name: item.name,
      });
      item?.children?.forEach((child) => {
        sysModule.push({
          id: child.code,
          name: child.name,
          parent: item.code,
          path: child.href,
        });
        if (!child.code.includes(".")) {
          sysModule.push({
            id: child.code + ".delete",
            name: child.name + "[删除]",
            parent: child.code,
          });
          sysModule.push({
            id: child.code + ".create",
            name: child.name + "[添加]",
            parent: child.code,
          });
          sysModule.push({
            id: child.code + ".update",
            name: child.name + "[更新]",
            parent: child.code,
          });
          sysModule.push({
            id: child.code + ".show",
            name: child.name + "[读取]",
            parent: child.code,
          });
        }
      });
    });
    for (const item of sysModule) {
      try {
        await dataProvider.create("sysModules", { data: item });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);
  // @field:NotEmpty
  // @field:NotNull
  //   var id: String,
  //     @field:NotEmpty
  // @field:NotNull
  //   var path: String,
  //     @field:NotEmpty
  // @field:NotNull
  //   var name: String,
  //     var parent: String? = null
  return (
    <LoadingButton label={"同步"} doLoading={handleClick} icon={<Sync />} />
  );
}
