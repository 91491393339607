import React from "react";
import { Pagination, ReferenceManyField, TabbedShowLayout } from "react-admin";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { MyField } from "../../components/types";
import { PayRecordTable } from "../PayRecord";

export function UserShow({ fields }: { fields: MyField<any>[] }) {
  return (
    <MyShow>
      <TabbedShowLayout value={0}>
        <TabbedShowLayout.Tab label="用户详细信息">
          <MyShowFields fields={fields} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="充值记录" path="userTypeOpRecords">
          <ReferenceManyField
            reference="payRecords"
            target="userId"
            source={"id"}
            label={false}
            pagination={<Pagination />}
          >
            <PayRecordTable />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        {/*<TabbedShowLayout.Tab*/}
        {/*  label="帐变"*/}
        {/*  path="userWalletLog"*/}
        {/*></TabbedShowLayout.Tab>*/}
      </TabbedShowLayout>
    </MyShow>
  );
}
