import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { UserApi } from "../../api/UserApi";
import { GroupApi } from "../../api/GroupApi";
import {
  RiChat1Line,
  RiChat2Line,
  RiChatOffFill,
  RiForbid2Line,
} from "@remixicon/react";
import {
  AutocompleteArrayInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  TextInput,
} from "react-admin";
import { GroupMemberApi } from "../../api/GroupMemberApi";

export interface MuteCommandProps extends CommandProps {}

MuteMemberCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as MuteCommandProps;

export function MuteMemberCommand(props: MuteCommandProps) {
  const { command, ...rest } = props;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: () => true,
        label: (r) => {
          if (r.muteEndTime > 0) {
            return "解禁";
          }
          return "禁言";
        },
        confirm: false,
        icon: (r) => {
          if (r.muteEndTime > 0) {
            return <RiChat2Line size={18} />;
          }
          return <RiChatOffFill size={18} />;
        },
        onHandle: async (e, r) => {
          if (r.muteEndTime > 0) {
            await GroupMemberApi.cancelMuteGroupMember(r.id);
          } else {
            const form = await confirm({
              title: "添加成员",
              form: (
                <>
                  <NumberInput
                    source="seconds"
                    label="禁言时间(分钟)"
                    fullWidth
                  />
                </>
              ),
            });
            //
            if (form) {
              await GroupMemberApi.muteGroupMember(r.id, form.seconds * 60);
            }
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
