import {
  CreateButton,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  RefreshButton,
  TopToolbar,
} from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { UploadMenuButton } from "./UploadMenuButton";
import { MyDatagridSelect } from "../../components/core/MyDatagrid";
import { ViewModule } from "@mui/icons-material";

const fields: MyField[] = [
  Field.text({
    label: "编码",
    source: "id",
    isList: false,
    isUpdate: false,
  }),
  Field.ref({
    label: "父节点",
    source: "parent",
    isRequired: false,
    isList: false,
    isFilter: false,
    reference: "sysModules",
    referenceLabel: "name",
    originSource: "parent",
  }),
  Field.text({
    label: "名称",
    source: "name",
    minWidth: 200,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
  }),
  Field.text({
    label: "路径",
    minWidth: 200,
    source: "path",
  }),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const groupingColDef: DataGridProProps["groupingColDef"] = {
  headerName: "编码",
  width: 260,
};

export const SysModuleSelectTable = (props: any) => {
  return (
    <MyDatagridSelect
      fields={fields}
      rowActions={[]}
      groupingColDef={groupingColDef}
      getTreeDataPath={(r: any) => {
        return r.treePath;
      }}
      isGroupExpandedByDefault={(r: any) => true}
    />
  );
};

const resource = {
  list: () => {
    return (
      <MyList
        emptyWhileLoading
        fields={fields}
        pagination={false}
        perPage={1000}
        rowActions={[<EditButton />, <DeleteButton />]}
        actions={
          <>
            <TopToolbar>
              <FilterButton />
              <CreateButton />
              <UploadMenuButton />
              <ExportButton />
              <RefreshButton />
            </TopToolbar>
          </>
        }
        groupingColDef={groupingColDef}
        getTreeDataPath={(r: any) => {
          return r.treePath;
        }}
        isGroupExpandedByDefault={(r: any) => true}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <ViewModule />,
};

export default resource;
