import * as React from "react";
import { CommonInputProps, TextInput } from "react-admin";
import Box from "@mui/material/Box";
import { InputLabel } from "@mui/material";

export interface MyTextInputProps extends CommonInputProps {}

export function MyTextInput(props: MyTextInputProps) {
  const { label, ...other } = props;

  return (
    <Box sx={{ p: "8px 0", width: "100%", maxWidth: "500px" }}>
      <InputLabel>{label}</InputLabel>
      <TextInput label={""} {...other} />
    </Box>
  );
}
