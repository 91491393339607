import { MyField } from "../../components/types";
import { ImageField } from "react-admin";
import * as React from "react";
import { MyImageInput } from "../../components/MyImageInput";
import { base, BaseArgs } from "./base";
import { TextArgs } from "./text";

export interface ImgArgs extends BaseArgs {}

const defaultArgs: Partial<TextArgs> = {
  isRequired: false,
  sortable: false,
  isFilter: false,
};

export function img(args: ImgArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: ImageField,
      props: {
        source: `${args.source}`,
        sx: {
          "& img": {
            maxWidth: "50px !important",
            maxHeight: "30px !important",
            margin: "0 !important",
            objectFit: "contain",
          },
          "&": {
            maxWidth: 50,
            maxHeight: 30,
            objectFit: "contain",
            overflow: "hidden",
          },
        },
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
      editComponent: MyImageInput,
      editProps: {
        accept: "image/*",
        children: <ImageField source="src" title="src" />,
      },
    },
    field
  );
}
