import { useInput } from "ra-core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Button, CommonInputProps } from "react-admin";
import Box from "@mui/material/Box";
import { IconButton, Input, InputLabel } from "@mui/material";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import remove from "lodash/remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { isString } from "lodash";

export interface MyTextArrayInputProps extends CommonInputProps {
  className?: string;
  multiline: false;
  minRows: 0;
}

export function MyTextArrayInput(props: MyTextArrayInputProps) {
  const {
    multiline,
    minRows,
    className,
    defaultValue = "",
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { value = [] } = field;

  useEffect(() => {
    if (value === "") {
      field.onChange([]);
    }
  }, [value]);

  const localesValue = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return [];
    }
    if (isString(value)) {
      return [value];
    }
    return value as String[];
  }, [field, field.value]);

  function changeValue(index: number, value: string) {
    const newValue = [...localesValue];
    newValue[index] = value;
    field.onChange(newValue);
  }

  function add() {
    const newValue = [...localesValue, ""];
    field.onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...localesValue];
    remove(newValue, (item, i) => i === index);
    field.onChange(newValue);
  }

  return (
    <Box sx={{ p: "8px 0", width: "100%", maxWidth: "500px" }}>
      <InputLabel>{label}</InputLabel>
      <Box display={"flex"} flexDirection={"column"}>
        {localesValue.map((item, index) => {
          return (
            <Input
              key={index}
              sx={{ flex: 1 }}
              value={item}
              multiline={multiline}
              minRows={minRows}
              onChange={(e) => changeValue(index, e.target.value)}
              endAdornment={
                <IconButton onClick={() => removeByIndex(index)}>
                  <RemoveCircleSharpIcon />
                </IconButton>
              }
            />
          );
        })}
      </Box>
      <Box p={"4px 0"}>
        <Button
          size={"medium"}
          onClick={() => add()}
          startIcon={<AddCircleIcon />}
          label={"添加"}
        />
      </Box>
    </Box>
  );
}
