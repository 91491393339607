import { Add } from "@mui/icons-material";
import { CommandProps } from "./Command";
import React from "react";
import { BaseCommand, BaseCommandProps } from "./BaseCommand";
import { useCreatePath, useResourceContext } from "ra-core";

export interface CreateCommandProps extends CommandProps {}

CreateCommand.defaultProps = {
  enableTool: true,
  enableRow: false,
  permission: "create",
} as BaseCommandProps;

export function CreateCommand(props: CreateCommandProps) {
  const { command, ...rest } = props;
  const createPath = useCreatePath();
  const resource = useResourceContext();
  return (
    <BaseCommand
      command={{
        to: createPath({ resource, type: "create" }),
        label: "新建",
        icon: <Add />,
        // to: (record, rd) => `#/${rd?.name}/create`,
        ...command,
      }}
      {...rest}
    />
  );
}
