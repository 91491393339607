import { MyField } from "../../components/types";
import { TextInput } from "react-admin";
import MyTextField from "../../components/MyTextField";
import { base, BaseArgs } from "./base";

export interface MaxTextArgs extends BaseArgs {
  multiline?: boolean;
  minRows?: number;
  maxLength?: number;
}

const defaultArgs: Partial<MaxTextArgs> = {
  isFilter: false,
  fullWidth: true,
  multiline: true,
  maxLength: 18,
  minRows: 4,
};

export function maxText(
  { multiline, minRows, maxLength, ...args }: MaxTextArgs,
  field?: MyField
) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyTextField,
      editComponent: TextInput,
      props: {
        maxLength: maxLength,
      },
      editProps: {
        multiline: multiline,
        minRows: minRows,
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
