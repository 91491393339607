import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { SysModuleSelectTable } from "./SysModule";
import { SupervisedUserCircle } from "@mui/icons-material";

const fields: MyField[] = [
  Field.text({
    label: "编码",
    source: "id",
    isList: false,
    isUpdate: false,
  }),
  Field.text({
    label: "名称",
    source: "name",
    minWidth: 200,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
  }),
  Field.refTable(
    {
      label: "权限",
      source: "moduleId",
      reference: "sysModules",
      fullWidth: true,
    },
    {
      editProps: {
        children: <SysModuleSelectTable />,
      },
    }
  ),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return <MyList fields={fields} pagination={false} perPage={1000} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <SupervisedUserCircle />,
};

export default resource;
