import * as React from "react";
import { useEffect } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
} from "react-admin";
import { useRecordContext } from "ra-core";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";

export const MyAutocompleteArrayInput = (
  props: AutocompleteArrayInputProps
) => {
  let { choices = [], source, ...rest } = props;

  const [inputChoices, setInputChoices] = React.useState<any[]>([]);

  const ref = React.useRef();
  const record = useRecordContext(props);
  const value = (get(record, source!!) as []) || [];
  useEffect(() => {
    choices.push(...value.map((v) => ({ id: v, name: v })));
    uniqBy(choices, function (e) {
      return e.id;
    });
  }, [value]);

  const inputText = (_: any) => {
    return "";
  };
  return (
    <AutocompleteArrayInput
      // createLabel="添加"
      ref={() => ref}
      createItemLabel="添加"
      inputText={inputText}
      choices={[...inputChoices, ...choices]}
      fullWidth
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          var value = e.target.value;
          const newTag = {
            id: value,
            name: value,
          };
          setInputChoices([...inputChoices, newTag]);
        }
      }}
      onCreate={(v: any) => {
        const newTag = { id: v, name: v };
        setInputChoices([...inputChoices, newTag]);
        return newTag;
      }}
      source={source}
      {...rest}
    />
  );
};
