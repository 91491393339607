import * as React from "react";
import { useMemo } from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Button, ButtonGroup, Menu, Theme, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isEmpty } from "lodash";
import { CommandProps } from "./Command";
import { usePermission } from "../../hook/usePermission";
import { RecordContextProvider, useResourceDefinition } from "react-admin";
import { useCheckResource } from "../../hook/useCheckResource";

type Item = React.ReactElement<GridActionsCellItemProps>;

interface MyProCommandsProps {
  row: any;
  showIcon: boolean;
  isInMenu: boolean;
  commands: React.ReactElement<CommandProps>[];
}

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

const isFragment = (element: any) => {
  return React.isValidElement(element) && element.type === React.Fragment;
};

ListRowCommands.defaultProps = {
  showIcon: false,
  isInMenu: true,
};

export function ListRowCommands(props: MyProCommandsProps) {
  const { row, showIcon, isInMenu } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const checkPermission = usePermission();
  const checkResource = useCheckResource();

  const [menuItems, buttons] = useMemo(() => {
    const menuItems: (React.ReactElement | undefined)[] = [];
    const buttons: (React.ReactElement | undefined)[] = [];

    let index = 0;
    props.commands.forEach((item, i) => {
      if (
        !item.props.enableRow ||
        !checkPermission(item.props.permission) ||
        !checkResource(item.props.permission)
      ) {
        return undefined;
      }
      if (isXSmall || index <= 2 || !isInMenu) {
        buttons.push(
          React.cloneElement(item, {
            key: `${i}`,
            isMenu: false,
            showIcon: showIcon,
          })
        );
      } else {
        menuItems.push(
          React.cloneElement(item, {
            key: `${i}`,
            isMenu: true,
            onClickEnd: handleClose,
          })
        );
      }
      index++;
    });

    return [menuItems, buttons];
  }, [props.commands, isXSmall]);

  return (
    <>
      <RecordContextProvider<any> value={row}>
        <ButtonGroup size={"small"} variant="outlined">
          {buttons}
          {!isEmpty(menuItems) && (
            <Button onClick={handleClick}>
              <MoreVertIcon fontSize={"small"} />
            </Button>
          )}
        </ButtonGroup>
        {!isEmpty(menuItems) && (
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {menuItems}
          </Menu>
        )}
      </RecordContextProvider>
    </>
  );
}
