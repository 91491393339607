import { MyField } from "../../components/types";
import { NumberField, NumberInput } from "react-admin";
import { base, BaseArgs } from "./base";
import { TextArgs } from "./text";

export interface EditNumberArgs extends BaseArgs {
  step?: number;
}

const defaultArgs: Partial<EditNumberArgs> = {
  isRequired: true,
  fullWidth: false,
  isFilter: false,
  step: 1,
};

export function editNumber(args: EditNumberArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: NumberField,
      editComponent: NumberInput,
      editProps: {
        step: args.step,
      },
    },
    field
  );
}
