import { IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { TextFieldProps, useRecordContext } from "react-admin";
import { Customer } from "../types";
import { ContentCopy } from "@mui/icons-material";
import CopyToClipboard from "./CopyToClipboard";

interface ObjectIdFieldProps extends TextFieldProps {
  fullText: boolean | undefined;
  fontSize?: number | string;
  showLabel?: string;
  maxLength?: number;
}

const MyTextField = (props: ObjectIdFieldProps) => {
  const {
    fullText = false,
    fontSize = "0.9rem",
    maxLength = 18,
    showLabel,
  } = props;
  const record = useRecordContext<Customer>();
  if (!record || !props.source) {
    return null;
  }
  const value = record[props.source];
  if (!value) {
    return null;
  }
  const text =
    fullText || value.length < maxLength
      ? `${value}`
      : `${value}`.toString().substring(0, maxLength) + "...";

  return (
    <Tooltip title={`${value}`}>
      <span style={{ fontSize: fontSize }}>
        {showLabel && `${showLabel} : `}
        {text}
        <CopyToClipboard>
          {({ copy }) => (
            <IconButton onClick={() => copy(value)}>
              <ContentCopy sx={{ fontSize: fontSize }} />
            </IconButton>
          )}
        </CopyToClipboard>
      </span>
    </Tooltip>
  );
};

export default MyTextField;
