import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { UserApi } from "../../api/UserApi";
import { GroupApi } from "../../api/GroupApi";
import { RiUserAddFill, RiUserAddLine } from "@remixicon/react";
import {
  AutocompleteArrayInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  TextInput,
} from "react-admin";
import { GroupMemberApi } from "../../api/GroupMemberApi";

export interface InviteUserCommandProps extends CommandProps {}

InviteUserCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as InviteUserCommandProps;

const renderUserOptionText = (record: any) =>
  `${record.viewId} (${record.nickname})`;

export function InviteUserCommand(props: InviteUserCommandProps) {
  const { command, ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id") as string;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      enableTool={false}
      command={{
        label: (r) => {
          return "添加成员";
        },
        confirm: false,
        icon: <RiUserAddLine size={18} />,
        onHandle: async (e, r) => {
          const form = await confirm({
            title: "添加成员",
            form: (
              <>
                <ReferenceArrayInput
                  source="userIDs"
                  label="群管理员列表"
                  reference="users"
                >
                  <AutocompleteArrayInput
                    optionText={renderUserOptionText}
                    label="群管理员列表"
                    optionValue="id"
                    fullWidth
                    validate={[required()]}
                  />
                </ReferenceArrayInput>
                <TextInput source="reason" label="邀请理由" fullWidth />
              </>
            ),
          });
          //
          if (form) {
            await GroupMemberApi.inviteUserToGroup(
              id,
              form.userIDs,
              form.reason
            );
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
