import { MenuItem } from "./model/menu";
import users from "./pages/user";
import groups from "./pages/group";
import payRecords from "./pages/PayRecord";
import Docs from "./pages/content/Doc";
import DocCategories from "./pages/content/DocCategory";
import SiteResource from "./pages/content/SiteResource";
import SiteNotification from "./pages/content/SiteNotification";
import AppVersion from "./pages/app/AppVersion";
import AppsPage from "./pages/app/AppsPage";
import Settings from "./pages/setting/setting";
import Admins from "./pages/Admin";
import LoginLogs from "./pages/LoginLog";
import SysModule from "./pages/sys/SysModule";
import SysRole from "./pages/sys/SysRole";
import PayProviders from "./pages/PayProvider";
import RobotProviders from "./pages/RobotProvider";
import groupRed from "./pages/groupRed";

export const menuItems: MenuItem[] = [
  {
    name: "会员管理",
    code: "userManager",
    icon: users.icon,
    children: [
      {
        name: "用户",
        icon: users.icon,
        code: "users",
        href: "/users",
      },
      {
        name: "充值记录",
        icon: payRecords.icon,
        code: "payRecords",
        href: "/payRecords",
      },
      {
        name: "群组",
        icon: groups.icon,
        code: "groups",
        href: "/groups",
      },
      {
        name: "群组成员",
        hide: true,
        icon: groups.icon,
        code: "groupMembers",
        href: "/groupMembers",
      },
      {
        name: "群红包",
        icon: groupRed.icon,
        code: "groupReds",
        href: "/groupReds",
      },
      {
        name: "红包领取记录",
        icon: groupRed.icon,
        code: "groupRedRecords",
        href: "/groupRedRecords",
      },
    ],
  },
  {
    name: "内容管理",
    icon: Docs.icon,
    code: "contentManager",
    children: [
      {
        name: "文章管理",
        icon: Docs.icon,
        code: "docs",
        href: "/docs",
      },
      {
        name: "文章分类",
        icon: DocCategories.icon,
        code: "docCategories",
        href: "/docCategories",
      },
      // {
      //   name: "页面信息",
      //   icon: WebPages.icon,
      //   code: "webPages",
      //   href: "/webPages",
      // },
      {
        name: "通知管理",
        icon: SiteNotification.icon,
        code: "siteNotification",
        href: "/siteNotification",
      },
      {
        name: "APP管理",
        icon: AppsPage.icon,
        code: "apps",
        href: "/apps",
      },
      {
        name: "APP版本管理",
        icon: AppVersion.icon,
        code: "appVersions",
        href: "/appVersions",
      },
      {
        name: "站点资源",
        icon: SiteResource.icon,
        code: "siteResource",
        href: "/siteResource",
      },
    ],
  },
  {
    name: "系统设置",
    icon: Settings.icon,
    code: "sysSetting",
    children: [
      {
        name: "站点设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/SiteSetting",
      },
      {
        name: "支付通道设置",
        icon: PayProviders.icon,
        code: "payProviders",
        href: "/payProviders",
      },
      {
        name: "机器人设置",
        icon: RobotProviders.icon,
        code: "robotProviders",
        href: "/robotProviders",
      },
    ],
  },
  {
    name: "管理员",
    icon: Admins.icon,
    code: "adminManager",
    children: [
      {
        name: "管理员",
        icon: Admins.icon,
        code: "admins",
        href: "/admins",
      },
      {
        name: "角色",
        icon: SysRole.icon,
        code: "sysRoles",
        href: "/sysRoles",
      },
      {
        name: "功能权限",
        icon: SysModule.icon,
        code: "sysModules",
        href: "/sysModules",
      },
      {
        name: "登录记录",
        icon: LoginLogs.icon,
        code: "loginLogs",
        href: "/loginLogs",
      },
    ],
  },
];
