import { MyField } from "../../components/types";
import { FileField } from "react-admin";
import * as React from "react";
import { base, BaseArgs } from "./base";
import { MyFileBase64Input } from "../../components/MyFileBase64Input";
import { MyFileField } from "../../components/MyFileField";
import { MyFileInput } from "../../components/MyFileInput";

export interface FileArgs extends BaseArgs {
  accept: string | string[];
  maxSize?: number;
  multiple?: boolean;
}

const defaultArgs: Partial<FileArgs> = {
  isRequired: false,
  sortable: false,
  isFilter: false,
  maxSize: 500_000_000, // 5MB
  multiple: true,
};

export function file(
  {
    accept,
    maxSize = defaultArgs.maxSize,
    multiple = defaultArgs.multiple,
    ...args
  }: FileArgs,
  field?: MyField
) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FileField,
      editComponent: MyFileInput,
      editProps: {
        accept: accept,
        multiple: multiple,
        maxSize: maxSize,
        children: <MyFileField source={`src`} title="name" />,
      },
    },
    field
  );
}
