import * as React from "react";
import { localStorageStore } from "ra-core";
import {
  AdminUI,
  AdminContext,
  AdminContextProps,
  AdminUIProps,
} from "ra-ui-materialui";
import { defaultI18nProvider } from "react-admin";
import { ConfirmDialogProvider } from "../../hook/ConfirmContext";

const defaultStore = localStorageStore();

export const MyAdmin = (props: AdminProps) => {
  const {
    authProvider,
    basename,
    catchAll,
    children,
    dashboard,
    dataProvider,
    disableTelemetry,
    history,
    i18nProvider = defaultI18nProvider,
    layout,
    loading,
    loginPage,
    authCallbackPage,
    menu, // deprecated, use a custom layout instead
    notification,
    queryClient,
    requireAuth,
    store = defaultStore,
    ready,
    theme,
    lightTheme,
    darkTheme,
    defaultTheme,
    title = "React Admin",
  } = props;

  if (loginPage === true && process.env.NODE_ENV !== "production") {
    console.warn(
      "You passed true to the loginPage prop. You must either pass false to disable it or a component class to customize it"
    );
  }

  return (
    <AdminContext
      authProvider={authProvider}
      basename={basename}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      store={store}
      history={history}
      queryClient={queryClient}
      theme={theme}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme={defaultTheme}
    >
      <ConfirmDialogProvider>
        <AdminUI
          layout={layout}
          dashboard={dashboard}
          disableTelemetry={disableTelemetry}
          menu={menu}
          catchAll={catchAll}
          title={title}
          loading={loading}
          loginPage={loginPage}
          authCallbackPage={authCallbackPage}
          notification={notification}
          requireAuth={requireAuth}
          ready={ready}
        >
          {children}
        </AdminUI>
      </ConfirmDialogProvider>
    </AdminContext>
  );
};

export default MyAdmin;

export interface AdminProps extends AdminContextProps, AdminUIProps {}
