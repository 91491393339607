import * as React from "react";
import { ReactElement, useMemo } from "react";
import { Button } from "react-admin";
import Box from "@mui/material/Box";
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Locales,
  LocalesItem,
  LocalesType,
  LocalesValue,
  toLocaleName,
} from "../model/Core";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import remove from "lodash/remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export interface BaseLocalesValueInputProps {
  value: LocalesItem[] | undefined | string;
  label: string | ReactElement | false;
  onChange: (e: LocalesItem[]) => void;
}

export function BaseLocalesValueInput(props: BaseLocalesValueInputProps) {
  const { value, onChange, label } = props;

  const localesValue = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return [{ locale: Locales[0], value: "" }];
    }
    return value as LocalesValue[];
  }, [value]);

  const leftTypes = useMemo(() => {
    let localesArray = [...Locales];
    localesValue.forEach((item) => {
      remove(localesArray, (i) => i === item.locale);
    });
    return localesArray;
  }, [value]);

  function changeValue(index: number, value: string) {
    const newValue = [...localesValue];
    newValue[index].value = value;

    onChange(newValue);
  }

  function changeLocale(locale: string, index: number) {
    const newValue = [...localesValue];
    newValue[index].locale = locale;
    onChange(newValue);
  }

  function add() {
    const newValue = [...localesValue, { locale: leftTypes[0], value: "" }];
    onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...localesValue];
    remove(newValue, (item, i) => i === index);
    onChange(newValue);
  }

  return (
    <Box p={"8px 0"}>
      <InputLabel>{label}</InputLabel>
      <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
        {localesValue.map((item, index) => {
          return (
            <Input
              key={index}
              value={item.value}
              onChange={(e) => changeValue(index, e.target.value)}
              startAdornment={
                <InputAdornment sx={{ width: "160px" }} position="start">
                  <LocalesSelect
                    value={item.locale}
                    locales={[item.locale, ...leftTypes]}
                    onChange={(locale) => changeLocale(locale, index)}
                  ></LocalesSelect>
                </InputAdornment>
              }
              endAdornment={
                <IconButton onClick={() => removeByIndex(index)}>
                  <RemoveCircleSharpIcon />
                </IconButton>
              }
            />
          );
        })}
      </Box>
      <Box p={"4px 0"}>
        {leftTypes.length > 0 && (
          <Button
            size={"medium"}
            onClick={() => add()}
            startIcon={<AddCircleIcon />}
            label={"添加"}
          />
        )}
      </Box>
    </Box>
  );
}

function LocalesSelect(props: {
  value: string;
  locales: string[];
  onChange: (e: string) => void;
}) {
  const { value, locales, onChange } = props;
  return (
    <Select<string>
      variant={"standard"}
      disableUnderline={true}
      value={value}
      size={"small"}
      onChange={(e) => onChange(e.target.value)}
    >
      {locales.map((item, i) => {
        return (
          <MenuItem value={item} key={i}>
            {toLocaleName(item as LocalesType)}
          </MenuItem>
        );
      })}
    </Select>
  );
}
