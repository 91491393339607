import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { UserApi } from "../../api/UserApi";

export interface LockAndUnLockCommandProps extends CommandProps {}

LockAndUnLockCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as LockAndUnLockCommandProps;

export function LockAndUnLockCommand(props: LockAndUnLockCommandProps) {
  const { command, ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id") as string;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          if (r.status === "LOCK") {
            return "解锁";
          }
          return "锁定";
        },
        confirm: true,
        icon: <CloudSync />,
        onHandle: async (e, r) => {
          if (r.status === "LOCK") {
            await UserApi.unlock(r.id);
          } else {
            await UserApi.lock(r.id);
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
