import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import ClassIcon from "@mui/icons-material/Class";
import { DataGridProProps } from "@mui/x-data-grid-pro";

const fields: MyField[] = [
  Field.text({
    label: "编码",
    source: "key",
    isFilter: false,
  }),
  Field.ref({
    label: "父节点",
    source: "parentId",
    isRequired: false,
    isList: false,
    isFilter: false,
    reference: "docCategories",
    referenceLabel: "key",
    originSource: "parentId",
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const groupingColDef: DataGridProProps["groupingColDef"] = {
  headerName: "ID",
  width: 160,
};

function transform(p: any) {
  if (p.parentId === "") {
    delete p.parentId;
  }
  return p;
}

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        groupingColDef={groupingColDef}
        getTreeDataPath={(r: any) => {
          return r.path;
        }}
        isGroupExpandedByDefault={(r: any) => true}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} transform={transform} />;
  },
  edit: () => {
    return <MyEdit fields={fields} transform={transform} />;
  },
  icon: <ClassIcon />,
};

export default resource;
