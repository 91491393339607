import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { RiUserAddLine } from "@remixicon/react";
import { TextInput } from "react-admin";
import { GroupMemberApi } from "../../api/GroupMemberApi";

export interface KickUserCommandProps extends CommandProps {}

KickUserCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as KickUserCommandProps;

export function KickUserCommand(props: KickUserCommandProps) {
  const { command, ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id") as string;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      enableTool={false}
      command={{
        label: (r) => {
          return "移除";
        },
        confirm: false,
        icon: <RiUserAddLine size={18} />,
        onHandle: async (e, r) => {
          const form = await confirm({
            title: "移除成员",
            form: (
              <>
                <TextInput source="reason" label="移除理由" fullWidth />
              </>
            ),
          });
          //
          if (form) {
            let [groupID, userId] = id.split("-");
            await GroupMemberApi.kickGroup(groupID, [userId], form.reason);
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
