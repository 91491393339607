import { TopToolbar } from "react-admin";
import { ListRowCommands } from "./ListRowCommands";
import * as React from "react";
import { CommandProps } from "./Command";
import { useShowContext } from "ra-core";

export interface DetailsTopToolbarProps {
  commands: React.ReactElement<CommandProps>[];
}

export function DetailsTopToolbar(props: DetailsTopToolbarProps) {
  const { commands } = props;
  const { resource, defaultTitle, record } = useShowContext();
  return (
    <TopToolbar>
      {record && (
        <ListRowCommands
          row={record}
          showIcon
          isInMenu={false}
          commands={commands}
        />
      )}
    </TopToolbar>
  );
}
