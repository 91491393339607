import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import PropTypes from "prop-types";
import { Identifier, RaRecord, SortPayload, useListContext } from "ra-core";
import * as React from "react";
import {
  ComponentType,
  FC,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import DatagridContextProvider from "ra-ui-materialui/dist/cjs/list/datagrid/DatagridContextProvider";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridDensity } from "@mui/x-data-grid/models/gridDensity";
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  DatagridRoot,
  RecordContextProvider,
  RowClickFunction,
} from "react-admin";
import { ExcludeProps, MyField } from "../types";
import omit from "lodash/omit";
import { Box, Theme, useMediaQuery } from "@mui/material";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { GridGroupNode, GridRowParams } from "@mui/x-data-grid";
import { zhCN } from "@mui/x-data-grid/locales";
import { ListRowCommands } from "../command/ListRowCommands";
import { CommandProps } from "../command/Command";
import { isEmpty } from "lodash";

const defaultBulkActionButtons = <BulkDeleteButton />;

const SIZE_DENSITY = {
  small: "compact",
  medium: "standard",
  large: "comfortable",
};

export function fieldsToColumns(fields: MyField[]) {
  return fields.map((f, i) => {
    const {
      component: FieldComponent,
      source,
      label,
      props,
      sortable = false,
      colSpan,
    } = f;

    let column: GridColDef = {
      type: "string",
      field: source || "",
      headerName: label,
      sortable: sortable,
      colSpan: colSpan,
      renderCell: (params) => {
        if (!FieldComponent) {
          return <></>;
        }
        return (
          <RecordContextProvider<any> value={params.row}>
            <FieldComponent
              source={source}
              label={label}
              sortable={sortable}
              {...omit(props, ExcludeProps)}
            />
          </RecordContextProvider>
        );
      },
    };
    const mix = (field: string) => {
      // @ts-ignore
      const v = f[field];
      if (!isUndefined(v) && !isNull(v)) {
        // @ts-ignore
        column[field] = v;
      }
    };
    mix("width");
    mix("maxWidth");
    mix("minWidth");
    if (f?.fullWidth) {
      column.flex = 1;
    }
    if (f?.props?.fullWidth) {
      column.flex = 1;
    }
    // if (!f?.props?.source) {
    //   column.type = "actions";
    //   column.field = "actions";
    // }
    return column;
  });
}

//'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions'

export const MyProDataGrid: FC<MyDataGridProps> = React.forwardRef(
  (props, ref) => {
    const {
      autosizeColumns,
      fields,
      commands,
      bulkActionButtons = defaultBulkActionButtons,
      isRowExpandable,
      size = "small",
      expandSingle = false,
    } = props;

    const {
      sort,
      data = [],
      isLoading,
      onSelect,
      // onToggleItem,
      selectedIds,
      setSort,
    } = useListContext(props);

    const sortModel = useMemo(() => {
      return [
        {
          field: sort.field || "id",
          sort: sort.order === "ASC" ? "asc" : "desc",
        },
      ] as GridSortModel;
    }, [sort]);

    function setSortModel(newSortModel: GridSortModel) {
      if (newSortModel?.length) {
        setSort({
          field: newSortModel[0].field || "id",
          order: newSortModel[0].sort === "asc" ? "ASC" : "DESC",
        });
      }
    }

    const apiRef = useGridApiRef();
    const contextValue = useMemo(() => ({ isRowExpandable, expandSingle }), [
      isRowExpandable,
      expandSingle,
    ]);
    const isXSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );

    const columns: GridColDef[] = useMemo(() => {
      let f = fieldsToColumns(fields);
      if (commands && commands.length > 0) {
        const renderCell =
          props.renderActionCell ||
          ((params: GridRenderCellParams<any, any, any>) => {
            if (!isUndefined(params.row.id) && !(params.row.id == null)) {
              return <ListRowCommands commands={commands} row={params.row} />;
            } else {
              return [];
            }
          });
        f.push({
          field: "commands",
          type: "actions",
          renderCell: renderCell,
        });
      }
      return f;
    }, [fields, commands, props.renderActionCell, isXSmall]);

    // columns.push({
    //   field: "actions",
    //   headerName: "",
    //   minWidth: 200,
    //   renderCell: (params: GridRenderCellParams<any, any, any>) => {
    //     return rowActions.map((a, i) => (
    //       <React.Fragment key={i}>
    //         <BaseGridActionsCellItem button={a} params={params} />
    //       </React.Fragment>
    //     ));
    //   },
    // });
    const density = SIZE_DENSITY[size] as GridDensity;

    const [rowSelectionModel, setRowSelectionModel] = React.useState<
      GridRowSelectionModel
    >(selectedIds);

    const [ok, setOk] = React.useState(false);
    useEffect(() => {
      const timeId = setTimeout(() => {
        setOk(true);
      }, 800);
      return () => {
        clearTimeout(timeId);
      };
    }, []);

    useEffect(() => {
      onSelect(rowSelectionModel);
    }, [rowSelectionModel]);

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    let onRowSelectionModelChange = useCallback(
      (newRowSelectionModel: GridRowSelectionModel, details: any) => {
        if (ok) {
          setRowSelectionModel(newRowSelectionModel);
        }
      },
      [ok]
    );
    useEffect(() => {
      if (autosizeColumns && !isEmpty(data) && apiRef.current) {
        apiRef.current.autosizeColumns({
          columns: ["commands"],
          includeHeaders: true,
          includeOutliers: true,
        });
      }
    }, [data, apiRef.current]);
    return (
      <DatagridContextProvider value={contextValue}>
        <DatagridRoot>
          {bulkActionButtons !== false ? (
            <BulkActionsToolbar selectedIds={selectedIds}>
              {isValidElement(bulkActionButtons)
                ? bulkActionButtons
                : defaultBulkActionButtons}
            </BulkActionsToolbar>
          ) : null}
          <Box display={"grid"}>
            <DataGridPro
              onRowSelectionModelChange={onRowSelectionModelChange}
              checkboxSelection
              rowSelectionModel={rowSelectionModel}
              // //@ts-ignore
              apiRef={apiRef}
              getTreeDataPath={props.getTreeDataPath}
              getDetailPanelContent={props.getDetailPanelContent}
              initialState={{
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                  right: ["commands"],
                },
              }}
              localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
              hideFooter
              autoHeight={true}
              pagination={false}
              disableColumnFilter
              density={density}
              disableRowSelectionOnClick
              columns={columns}
              groupingColDef={props.groupingColDef}
              rows={data}
              isGroupExpandedByDefault={props.isGroupExpandedByDefault}
              treeData={!!props.getTreeDataPath}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              sortModel={sortModel}
              loading={isLoading}
            />
          </Box>
        </DatagridRoot>
      </DatagridContextProvider>
    );
  }
);

MyProDataGrid.propTypes = {
  // @ts-ignore
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  tableActions: PropTypes.any,
  // @ts-ignore-line
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  className: PropTypes.string,
  sort: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.any),
  empty: PropTypes.element,
  // @ts-ignore
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  // @ts-ignore
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hover: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  setSort: PropTypes.func,
  total: PropTypes.number,
  isRowSelectable: PropTypes.func,
  isRowExpandable: PropTypes.func,
  expandSingle: PropTypes.bool,
  getTreeDataPath: PropTypes.func,
  isGroupExpandedByDefault: PropTypes.func,
  groupingColDef: PropTypes.any,
};

export interface MyDataGridProps<RecordType extends RaRecord = any> {
  autosizeColumns?: boolean;
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  fields: MyField<RecordType>[];
  body?: ReactElement | ComponentType;
  commands?: React.ReactElement<CommandProps>[];
  className?: string;
  bulkActionButtons?: ReactElement | false;
  expand?:
    | ReactElement
    | FC<{
        id: Identifier;
        record: RecordType;
        resource: string;
      }>;
  header?: ReactElement | ComponentType;
  hover?: boolean;
  empty?: ReactElement;
  isRowSelectable?: (record: RecordType) => boolean;
  isRowExpandable?: (record: RecordType) => boolean;
  rowClick?: string | RowClickFunction;
  rowStyle?: (record: RecordType, index: number) => any;
  size?: "medium" | "small" | "large";
  // can be injected when using the component without context
  sort?: SortPayload;
  data?: RecordType[];
  isLoading?: boolean;
  onSelect?: (ids: GridRowId[]) => void;
  onToggleItem?: (id: Identifier) => void;
  setSort?: (sort: SortPayload) => void;
  selectedIds?: GridRowId[];
  expandSingle?: boolean;
  total?: number;
  getTreeDataPath?: (row: RecordType) => string[];
  isGroupExpandedByDefault?: (node: GridGroupNode) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
  getDetailPanelContent?: (
    params: GridRowParams<RecordType>
  ) => React.ReactNode;
}

MyProDataGrid.displayName = "MyProDataGrid";
