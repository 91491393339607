import { EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { LocalAtm } from "@mui/icons-material";
import { UserLabel } from "../../utils/userUtils";
import { GroupRedStatusChoices } from "../../model/GroupRed";
import { MyRefTable } from "../../components/core/MyRefTable";
import { GroupRedShow } from "./GroupRedShow";

const fields: MyField<any>[] = [
  Field.objectId(),
  Field.ref({
    label: "群",
    source: "groupId",
    reference: "groups",
    sortable: false,
    isFilter: false,
    referenceLabel: "groupName",
  }),
  Field.select({
    label: "状态",
    source: "status",
    choices: GroupRedStatusChoices,
  }),
  Field.ref({
    label: "发送用户",
    source: "userId",
    reference: "users",
    sortable: false,
    isFilter: false,
    minWidth: 150,
    translateLabel: UserLabel,
  }),
  Field.amount({
    label: "金额",
    source: "amount",
  }),
  Field.amount({
    label: "最大金额",
    source: "maxAmount",
  }),
  Field.number({
    label: "数量",
    source: "count",
  }),
  Field.functionField({
    label: "领取数量",
    source: "surplusCount",
    render: (r) => r.count - r.surplusCount,
  }),
  Field.text({
    label: "消息",
    source: "message",
  }),
  Field.dateTime({
    label: "领取结束时间",
    source: "receiveEndTime",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const GroupRedTable = () => {
  return <MyRefTable fields={fields} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[<EditButton />]} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  show: () => {
    return <GroupRedShow fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <LocalAtm />,
};

export default resource;
