import VisitorIcon from "@mui/icons-material/People";
import { Admin } from "../../model/Admin";
import * as React from "react";
import { MyField } from "../../components/types";
import omit from "lodash/omit";
import { Field } from "../../utils/Field";
import { MyList } from "../../components/core/MyList";
import MyEdit from "../../components/core/MyEdit";
import GroupCreate from "./GroupCreate";
import GroupEdit from "./GroupEdit";
import { ResourceType } from "../../components/core/MyResource";
import { ShowCommand } from "../../components/command/ShowCommand";
import { CreateCommand } from "../../components/command/CreateCommand";
import { EditCommand } from "../../components/command/EditCommand";
import { AmountCommand } from "../user/AmountCommand";
import { LockAndUnLockCommand } from "../user/LockAndUnLockCommand";
import { DeleteCommand } from "../../components/command/DeleteCommand";
import { MuteCommand } from "./MuteCommand";
import { GroupShow } from "./GroupShow";
import { InviteUserCommand } from "../groupMember/InviteUserCommand";

const fields: MyField<any>[] = [
  Field.id({
    label: "群ID",
    minWidth: 134,
    isFilter: true,
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.text({
    label: "群ID",
    source: "groupID",
    minWidth: 120,
    isList: false,
    isFilter: true,
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.img({
    maxWidth: 80,
    label: "头像",
    source: "faceURL",
    isEdit: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.text({
    label: "群组名称",
    source: "groupName",
    sortable: false,
    isFilter: true,
  }),
  Field.functionField({
    label: "状态",
    source: "status",
    sortable: false,
    isFilter: false,
    render(record: any, source: string | undefined): any {
      switch (record.status) {
        case 0:
          return "正常";
        case 1:
          return "封禁";
        case 2:
          return "解散";
        case 3:
          return "禁言";
        default:
          return "未知状态";
      }
    },
  }),
  Field.ref({
    label: "创建人",
    source: "creatorUserID",
    reference: "users",
    sortable: false,
    isFilter: false,
    referenceLabel: "name",
  }),
  Field.ref({
    label: "群主",
    source: "ownerUserID",
    reference: "users",
    sortable: false,
    isFilter: false,
    referenceLabel: "name",
  }),
  Field.number({
    label: "成员数量",
    source: "memberCount",
    sortable: false,
    isFilter: false,
  }),
  Field.text({
    label: "公告",
    source: "notification",
    sortable: false,
    isFilter: false,
  }),
  Field.localRef({
    label: "修改公告用户",
    source: "notificationUserID",
    reference: "users",
    sortable: false,
    isFilter: false,
    referenceLabel: "nickName",
  }),
  Field.text({
    label: "介绍",
    source: "introduction",
    sortable: false,
    isFilter: false,
  }),
  Field.functionField({
    label: "进群验证",
    source: "needVerification",
    sortable: false,
    isFilter: false,
    render(record: any, source: string | undefined): any {
      switch (record.needVerification) {
        case 0:
          return "不用验证";
        case 1:
          return "需要验证";
        case 2:
          return "直接进入";
        default:
          return "未知状态";
      }
    },
  }),
  Field.functionField({
    label: "成员信息",
    source: "lookMemberInfo",
    sortable: false,
    isFilter: false,
    render(record: any, source: string | undefined): any {
      switch (record.lookMemberInfo) {
        case 0:
          return "公开";
        case 1:
          return "不公开";
        default:
          return "未知状态";
      }
    },
  }),
  Field.functionField({
    label: "添加好友",
    source: "applyMemberFriend",
    sortable: false,
    isFilter: false,
    render(record: any, source: string | undefined): any {
      switch (record.lookMemberInfo) {
        case 0:
          return "允许";
        case 1:
          return "不允许";
        default:
          return "未知状态";
      }
    },
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createTime",
  }),
];

let transform = (data: any) => {
  if (data?.nickName) {
    return {
      ...omit(data, ["nickName"]),
      groupOwnerID: data?.nickName,
    };
  }
  return data;
};

const resource: ResourceType = {
  commands: [
    <ShowCommand />,
    <CreateCommand />,
    <EditCommand command={{ isEnable: (rowData) => rowData.status !== 2 }} />,
    <MuteCommand />,
    <InviteUserCommand />,
    <DeleteCommand
      command={{ label: "解散", isEnable: (rowData) => rowData.status !== 2 }}
    />,
  ],
  show: () => {
    return <GroupShow fields={fields} />;
  },
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <GroupCreate />;
  },
  edit: () => {
    return <GroupEdit />;
  },
  icon: <VisitorIcon />,
};

export default resource;
