import { Box, Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import * as React from "react";
import { useMemo } from "react";
import { useChildMenu, useMainMenuName } from "../hook/MenuCentext";
import { useLocation, useMatch } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export interface ContentProps {
  children: React.ReactNode;
}

export function Content({ children }: ContentProps) {
  const location = useLocation();
  const childMenu = useChildMenu();
  const mainMenuName = useMainMenuName();
  const first = childMenu?.children?.[0];
  const selectMenu = useMemo(() => {
    return childMenu?.children?.find((menu) => {
      return location.pathname.startsWith(menu.href!);
    });
  }, [childMenu, location.pathname]);
  const isShow = !!useMatch(selectMenu?.href + "/:id/show/*");
  const isCreate = !!useMatch(selectMenu?.href + "/create");
  const isEdit = !!useMatch(selectMenu?.href + "/:id");
  let current: string | undefined = undefined;
  if (isShow) {
    current = "详情";
  } else if (isCreate) {
    current = "创建";
  } else if (isEdit) {
    current = "编辑";
  }
  return (
    <Box
      flex={1}
      display={"flex"}
      width={"100%"}
      flexDirection={"column"}
      alignItems={"stretch"}
    >
      <Box sx={{ padding: "12px 4px 4px 4px" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link color="inherit" href={"#" + first?.href} underline="hover">
            {mainMenuName}
          </Link>{" "}
          {current ? (
            <Link
              color="inherit"
              href={"#" + selectMenu?.href}
              underline="hover"
            >
              {selectMenu?.name}
            </Link>
          ) : (
            <Typography color="text.primary"> {selectMenu?.name}</Typography>
          )}
          {current && <Typography color="text.primary">{current}</Typography>}
        </Breadcrumbs>
      </Box>
      <Box flex={1} width={"100%"}>
        {children}
      </Box>
    </Box>
  );
}
