export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      let data = reader.result as string;
      // data = data.split(";base64,")[1];
      resolve(data);
    };
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
