import { useResourceContext } from "ra-core";
import { isEmpty } from "lodash";
import { useResourceDefinition } from "react-admin";
import { useResourceConfig } from "./ResourceConfigContext";

export function useCheckResource() {
  const resource = useResourceContext();
  const resourceDefinition = useResourceDefinition();
  const { hasDelete } = useResourceConfig();

  return function checkResource(permission?: string) {
    if (isEmpty(permission)) {
      return true;
    }
    if (permission === "create") {
      return resourceDefinition.hasCreate;
    } else if (permission === "update") {
      return resourceDefinition.hasEdit;
    } else if (permission === "show") {
      return resourceDefinition.hasShow;
    } else if (permission === "delete") {
      return hasDelete;
    } else {
      return false;
    }
  };
}
