import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface ConfirmDialogProps {
  isOpen: boolean;
  title?: string;
  description?: React.ReactNode;
  onClose: (confirmed: boolean) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  description,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{ minWidth: "320px" }}>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          取消
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
