import * as React from "react";
import { ReactElement } from "react";
import PropTypes from "prop-types";
import { RaRecord, ShowBase } from "ra-core";

import { ShowProps, ShowView } from "react-admin";
import { DetailsTopToolbar } from "../command/DetailsTopToolbar";
import { useCommands } from "../../hook/ResourceConfigContext";

export const MyShow = <RecordType extends RaRecord = any>({
  id,
  resource,
  queryOptions,
  disableAuthentication,
  ...rest
}: ShowProps<RecordType>): ReactElement => {
  const commands = useCommands();
  // debugger;
  return (
    <ShowBase<RecordType>
      id={id}
      disableAuthentication={disableAuthentication}
      queryOptions={queryOptions}
      resource={resource}
    >
      <ShowView
        {...rest}
        // actions={false}
        actions={commands && <DetailsTopToolbar commands={commands} />}
      />
    </ShowBase>
  );
};

MyShow.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disableAuthentication: PropTypes.bool,
  emptyWhileLoading: PropTypes.bool,
  component: PropTypes.elementType,
  resource: PropTypes.string,
  title: PropTypes.node,
  sx: PropTypes.any,
};
