import * as React from "react";
import { useMemo } from "react";
import {
  AppBar,
  Logout,
  useAuthState,
  UserMenu,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  svgIconClasses,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  useMediaQuery,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { menuItems } from "../MenuData";
import { useChildMenu } from "../hook/MenuCentext";
import { styled } from "@mui/material/styles";
import { AccountStorage } from "../AccountStorage";

const ConfigurationMenu = React.forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/configuration"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate("pos.configuration")}</ListItemText>
    </MenuItem>
  );
});
const CustomUserMenu = () => (
  <UserMenu>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    fontSize: theme.typography.subtitle1.fontSize,
    padding: theme.spacing(0.5, 1.2),
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent",
  },
  [`& .${svgIconClasses.root}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

const CustomAppBar = (props: any) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("md")
  );
  const childMenu = useChildMenu();
  const { isLoading, authenticated } = useAuthState();
  const menus = useMemo(
    () => menuItems.filter((m) => AccountStorage.hasModule(m.code)),
    [isLoading, authenticated]
  );
  return (
    <AppBar
      {...props}
      color="secondary"
      elevation={1}
      userMenu={<CustomUserMenu />}
    >
      {isLargeEnough ? (
        <Box component="span" sx={{ flex: 1, gap: "8px", display: "flex" }}>
          <StyledToggleButtonGroup value={childMenu?.name} exclusive>
            {menus.map((item) => {
              return (
                <ToggleButton
                  size={"medium"}
                  key={item.name}
                  value={item.name}
                  href={"#" + item.children?.[0]?.href}
                >
                  {item.icon} {item.name}
                </ToggleButton>
              );
            })}
          </StyledToggleButtonGroup>
        </Box>
      ) : (
        <Box component="span" sx={{ flex: 1 }}></Box>
      )}
    </AppBar>
  );
};

export default CustomAppBar;
