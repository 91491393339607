import { CommandProps } from "../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { NumberInput, required } from "react-admin";
import { MyTabSelectInput } from "../../components/MyTabSelectInput";
import { UserApi } from "../../api/UserApi";

export interface AmountCommandProps extends CommandProps {
  isPayment?: boolean;
}

AmountCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
  isPayment: true,
} as AmountCommandProps;

export function AmountCommand(props: AmountCommandProps) {
  const { command, isPayment, ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id") as string;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          if (isPayment) {
            return "充值";
          }
          return "扣款";
        },
        icon: <CloudSync />,
        onHandle: async () => {
          const form = await confirm({
            title: isPayment ? "充值" : "扣款",
            form: (
              <>
                <NumberInput
                  label={"金额"}
                  isRequired
                  validate={[required()]}
                  source={"amount"}
                  defaultValue={1}
                />
              </>
            ),
          });
          //
          if (form) {
            if (isPayment) {
              await UserApi.handPay(id, form.amount);
            } else {
              await UserApi.handDeduct(id, form.amount);
            }
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
