import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonProps,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslate } from "ra-core";
import { useTiptapEditor } from "../useTiptapEditor";
import { ImageUpload } from "../../ImageUpload";

export const ImageButtons = (props: Omit<ToggleButtonProps, "value">) => {
  const translate = useTranslate();
  const editor = useTiptapEditor();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState<string>("");
  const [width, setWidth] = React.useState<string>("");
  const [borderRadius, setBorderRadius] = React.useState<string>("");

  const handleClickOpen = () => {
    const { width, borderRadius, src } = editor?.getAttributes("image") || {};
    setWidth(width);
    setBorderRadius(borderRadius);
    setImage(src);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const label = translate("ra.tiptap.image", { _: "Image" });

  const addImage = React.useCallback(() => {
    if (image) {
      editor
        ?.chain()
        .focus()
        .setMyImage({ src: image, width: width, borderRadius })
        .run();
      setOpen(false);
      setImage("");
    }
  }, [editor, translate, image, open, width, borderRadius]);

  function onSuccess(result?: string[]) {
    if (result && result.length > 0) {
      setImage(result[0]);
    }
  }

  return editor ? (
    <>
      <ToggleButton
        aria-label={label}
        title={label}
        {...props}
        disabled={!editor?.isEditable}
        value="image"
        onClick={handleClickOpen}
      >
        <ImageIcon fontSize="inherit" />
      </ToggleButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{"上传文件"}</DialogTitle>
        <DialogContent>
          <ImageUpload id={"img"} onSuccess={onSuccess} />
          <Stack>
            <TextField
              id="outlined-basic"
              label="图片宽度"
              variant="outlined"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              fullWidth={true}
              sx={{ minWidth: "250px", maxWidth: "100%" }}
            />
            <TextField
              id="outlined-basic"
              label="图片圆角"
              variant="outlined"
              value={borderRadius}
              onChange={(e) => setBorderRadius(e.target.value)}
              fullWidth={true}
              sx={{ minWidth: "250px", maxWidth: "100%" }}
            />
          </Stack>
          <TextField
            id="outlined-basic"
            label="图片地址"
            variant="outlined"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            fullWidth={true}
            sx={{ minWidth: "250px", maxWidth: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={addImage} autoFocus>
            插入
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};
