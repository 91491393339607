import VisitorIcon from "@mui/icons-material/People";
import * as React from "react";
import { MyField } from "../components/types";
import omit from "lodash/omit";
import { convertFileToBase64 } from "../utils/fileToBase64";
import { Field } from "../utils/Field";
import { MyList } from "../components/core/MyList";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import { ResourceType } from "../components/core/MyResource";

import { useCreate, useCreateSuggestionContext } from "react-admin";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { MyShow } from "../components/core/MyShow";
import { MyShowFields } from "../components/MyShowFields";

const CreateTag = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");
  const [create] = useCreate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
      "tags",
      {
        data: {
          title: value,
        },
      },
      {
        onSuccess: (data) => {
          setValue("");
          onCreate(data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New tag"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
const categories = [
  { name: "Tech", id: "tech" },
  { name: "Lifestyle", id: "lifestyle" },
];
const fields: MyField[] = [
  Field.objectId({
    isList: false,
    isUpdate: false,
    isFilter: false,
  }),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.maxText({
    label: "令牌(Key)",
    source: "key",
    isCreate: false,
    isEdit: false,
    isUpdate: false,
  }),
  Field.maxText({
    label: "密钥(secret)",
    source: "secretKey",
    isList: false,
    isCreate: false,
    isShow: true,
    maxLength: 500,
    isEdit: false,
    isUpdate: false,
  }),
  Field.autocompleteArray({
    fullWidth: true,
    label: "ip白名单",
    source: "ip",
  }),
  Field.dateTime({
    label: "修改时间",
    source: "updateDate",
  }),
];

let transform = async (data: any) => {
  if (data?.key?.rawFile) {
    const key = await convertFileToBase64(data.key.rawFile);
    return { ...data, key };
  }
  if (data.key && data.key.src) {
    return { ...data, key: data.key.src };
  }
  return { ...omit(data, ["key"]), key: "" };
};

const resource: ResourceType = {
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <MyCreate fields={fields} transform={transform} />;
  },
  edit: () => {
    return <MyEdit fields={fields} transform={transform} />;
  },
  icon: <VisitorIcon />,
};

export default resource;
