export enum AppPlatform {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export const AppPlatformChoices = [
  {
    id: AppPlatform.ANDROID,
    name: "Android",
  },
  {
    id: AppPlatform.IOS,
    name: "iOS",
  },
];

export enum AppUpdateTypeVersion {
  DOWN = "DOWN",
  URL = "URL",
}

export const AppUpdateTypeVersionChoices = [
  {
    id: AppUpdateTypeVersion.DOWN,
    name: "下载安装",
  },
  {
    id: AppUpdateTypeVersion.URL,
    name: "跳转地址",
  },
];
