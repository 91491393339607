export enum SiteResourceType {
  HTML = "HTML",
  IMAGE = "IMAGE",
  IMAGES = "IMAGES",
  // VIDEO = "VIDEO",
}

export const SiteResourceTypeChoices = [
  { id: SiteResourceType.HTML, name: "网页" },
  { id: SiteResourceType.IMAGE, name: "图片" },
  { id: SiteResourceType.IMAGES, name: "图片集" },
  // { id: SiteResourceType.VIDEO, name: "视频" },
];
