import { EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { LocalAtm } from "@mui/icons-material";
import { UserLabel } from "../../utils/userUtils";
import { GroupRedRecordStatusChoices } from "../../model/GroupRed";
import { MyRefTable } from "../../components/core/MyRefTable";

const fields: MyField<any>[] = [
  Field.objectId(),
  Field.ref({
    label: "群",
    source: "groupId",
    reference: "groups",
    sortable: false,
    isFilter: false,
    referenceLabel: "groupName",
  }),
  Field.ref({
    label: "红包ID",
    source: "groupRedId",
    reference: "groupReds",
    sortable: false,
    isFilter: false,
    referenceLabel: "id",
  }),
  Field.select({
    label: "状态",
    source: "status",
    choices: GroupRedRecordStatusChoices,
  }),
  Field.ref({
    label: "接受用户",
    source: "receiveUserId",
    reference: "users",
    sortable: false,
    isFilter: false,
    minWidth: 150,
    translateLabel: UserLabel,
  }),
  Field.amount({
    label: "金额",
    source: "amount",
  }),
  Field.boolean({
    label: "是否最佳",
    source: "isMax",
  }),
  Field.dateTime({
    label: "领取时间",
    source: "receiveDate",
  }),
];

export const GroupRedRecordTable = () => {
  return <MyRefTable fields={fields} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[<EditButton />]} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <LocalAtm />,
};

export default resource;
