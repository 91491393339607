import { MyField } from "../../components/types";
import { OptionText, ReferenceArrayField, TextField } from "react-admin";
import * as React from "react";
import { base, BaseArgs } from "./base";
import { MyReferenceTableInput } from "../../components/MyReferenceTableInput";
import MyTextField from "../../components/MyCountField";

export interface RefTableArgs extends BaseArgs {
  /**
   * 引用表, 例如: "users"
   */
  reference: string;
}

/**
 * Field.ref({
 *     label: "限制币种",
 *     source: "symbolTradeLimitList",
 *     reference: "coins",
 *     referenceLabel: "symbol",
 *     originSource: "symbolTradeLimitList",
 *     optionValue: "symbol",
 *     isFilter: false,
 *   }),
 */

const defaultArgs: Partial<RefTableArgs> = {
  fullWidth: false,
  isFilter: false,
  isRequired: false,
};

export const refTable = (args: RefTableArgs, field?: MyField): MyField => {
  const { reference } = args;

  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyTextField,
      editComponent: MyReferenceTableInput,
      editProps: {
        source: args.source,
        // valueSource: referenceValueSource,
        reference: reference,
        name: args.source,
      },
    },
    field
  );
};
