import { Command, defaultCommandProps } from "../../model/command";
import { CommandProps } from "./Command";
import * as React from "react";
import { useMemo } from "react";
import { useConfirm } from "../../hook/ConfirmContext";
import { useRefresh, useResourceDefinition } from "react-admin";
import { useRecordContext } from "ra-core";
import { isString } from "lodash";
import isFunction from "lodash/isFunction";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { LoadingButton } from "../LoadingButton";
import { Link } from "react-router-dom";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsVariantOverrides } from "@mui/material/Button/Button";

export interface BaseCommandProps extends CommandProps {
  confirmTitle?: string;
  confirmContent?: string;
  variant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
}

export function BaseCommand(props: BaseCommandProps) {
  const {
    command,
    showIcon,
    isMenu,
    confirmTitle,
    confirmContent,
    variant,
    onClickEnd,
  } = props;
  const { label, icon, onHandle, children, to, isEnable, color } = {
    ...defaultCommandProps,
    ...command,
  };
  const confirm = useConfirm();
  const resourceDefinition = useResourceDefinition();
  const rowData = useRecordContext();
  const refresh = useRefresh();
  const [loading, setLoading] = React.useState(false);
  const href = React.useMemo(() => {
    if (isString(to)) {
      return to;
    } else if (isFunction(to)) {
      return to(rowData, resourceDefinition);
    }
    return undefined;
  }, [to, rowData, resourceDefinition]);

  const iconEle = useMemo(() => {
    if (isFunction(icon)) {
      return icon(rowData, loading);
    } else {
      return icon;
    }
  }, [icon]);

  const disabled = isEnable && !isEnable(rowData);
  const labelValue = isFunction(label) ? label(rowData) : label;
  async function onClick(e: React.MouseEvent) {
    try {
      setLoading(true);
      if (onHandle) {
        if (command.confirm) {
          const isConfirmed = await confirm({
            title: confirmTitle || "是否确定?",
            description: confirmContent || "是否: " + labelValue || "",
          });
          if (!isConfirmed) {
            return;
          }
        }
        await onHandle(e, rowData);
        refresh();
      }
    } finally {
      setLoading(false);
    }
  }

  if (isMenu) {
    if (href) {
      return (
        <MenuItem disabled={disabled} to={href} component={Link}>
          <ListItemIcon>{iconEle}</ListItemIcon>
          <ListItemText>{labelValue}</ListItemText>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          disabled={disabled}
          onClick={async (e) => {
            await onClick(e);
            onClickEnd?.(e);
          }}
        >
          <ListItemIcon>{iconEle}</ListItemIcon>
          <ListItemText>{labelValue}</ListItemText>
        </MenuItem>
      );
    }
  } else {
    return (
      <LoadingButton
        component={Link}
        variant={variant}
        color={color}
        disabled={disabled}
        to={href}
        doLoading={async (e) => {
          await onClick(e);
        }}
        size={"small"}
        icon={showIcon ? iconEle : undefined}
      >
        {labelValue}
        {children?.(rowData)}
      </LoadingButton>
    );
  }
}
