import { MyField } from "../../components/types";
import { TextField, TextInput } from "react-admin";
import { base, BaseArgs } from "./base";
import isUndefined from "lodash/isUndefined";

export interface TextArgs extends BaseArgs {
  maxLength?: number;
  multiline?: boolean;
  minRows?: number;
}

const defaultArgs: Partial<TextArgs> = {
  isRequired: false,
  fullWidth: false,
  maxLength: 100,
  multiline: false,
  minRows: 0,
};

export function text(
  { multiline, minRows, ...args }: TextArgs,
  field?: MyField
) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: TextField,
      editComponent: TextInput,
      props: {
        maxLength: isUndefined(args.maxLength)
          ? defaultArgs.maxLength
          : args.maxLength,
      },
      editProps: {
        multiline: multiline,
        minRows: minRows,
        sx: { maxWidth: "500px" },
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
