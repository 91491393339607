import { MyField } from "../../components/types";
import { FunctionField, TextField, TextInput } from "react-admin";
import { base, BaseArgs } from "./base";
import isUndefined from "lodash/isUndefined";
import { MyTextArrayInput } from "../../components/MyTextArrayInput";

export interface TextArrayArgs extends BaseArgs {
  multiline?: boolean;
  minRows?: number;
}

const defaultArgs: Partial<TextArrayArgs> = {
  isRequired: false,
  fullWidth: false,
  multiline: false,
  isFilter: false,
  minRows: 0,
};

export function textArray(
  { multiline, minRows, ...args }: TextArrayArgs,
  field?: MyField
) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FunctionField,
      props: {
        source: args.source,
        render: (r: any) => {
          return `${r?.[args.source]?.length || ""}`;
        },
      },
      editComponent: MyTextArrayInput,
      editProps: {
        multiline: multiline,
        minRows: minRows,
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
