import VisitorIcon from "@mui/icons-material/People";
import {
  ExportButton,
  FilterButton,
  RefreshButton,
  TopToolbar,
} from "react-admin";
import * as React from "react";
import { MyField } from "../components/types";
import { Field } from "../utils/Field";
import { BooleanTypeChoices, PayRecordTypeData } from "../model/Core";
import { MyList } from "../components/core/MyList";
import { MyDatagrid } from "../components/core/MyDatagrid";
import { MyRefTable } from "../components/core/MyRefTable";
import { UserLabel } from "../utils/userUtils";

const fields: MyField[] = [
  Field.objectId(),
  Field.select({
    label: "类型",
    source: "type",
    choices: PayRecordTypeData,
  }),
  Field.ref({
    label: "用户",
    source: "userId",
    reference: "users",
    isFilter: false,
    minWidth: 150,
    translateLabel: UserLabel,
  }),
  Field.text({
    label: "用户",
    source: "nickName",
    isList: false,
    isFilter: true,
  }),
  Field.ref({
    label: "四方",
    source: "providerId",
    reference: "payProviders",
  }),
  // Field.ref({
  //   label: "机器人",
  //   source: "robotProviderId",
  //   reference: "robotProviders",
  // }),
  Field.ref({
    label: "操作员",
    source: "adminId",
    reference: "admins",
    isFilter: false,
  }),
  Field.amount({
    label: "金额",
    source: "amount",
    isFilter: false,
  }),
  Field.select({
    label: "是否成功",
    source: "isOk",
    choices: BooleanTypeChoices,
    isFilter: false,
  }),
  Field.dateTime({
    label: "成功时间",
    source: "payOkTime",
  }),
  // Field.dateTime({
  //   label: "创建时间",
  //   source: "createdDate",
  //   isFilter: false,
  // }),
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
    <RefreshButton />
  </TopToolbar>
);

export const PayRecordTable = () => {
  return <MyRefTable fields={fields} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} actions={<ListActions />} />;
  },
  icon: <VisitorIcon />,
};

export default resource;
