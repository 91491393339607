import { useInput } from "ra-core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import {
  Locales,
  LocalesType,
  LocalesValue,
  toLocaleName,
} from "../model/Core";
import remove from "lodash/remove";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Typography,
} from "@mui/material";
import { Button, CommonInputProps } from "react-admin";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RichTextInput } from "./richText";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";

export interface MyRichTextInputProps extends CommonInputProps {
  className?: string;
}

export function MyRichTextInput(props: MyRichTextInputProps) {
  const {
    className,
    defaultValue = "",
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { name, value = [], ref } = field;
  const [selectTab, setSelectTab] = React.useState("0");

  const handleTableChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectTab(newValue);
  };
  useEffect(() => {
    if (value === "") {
      field.onChange([]);
    }
  }, [value]);
  const localesValue = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return [{ locale: Locales[0], value: "" }];
    }
    return value as LocalesValue[];
  }, [field, field.value]);

  const leftTypes = useMemo(() => {
    let localesArray = [...Locales];
    localesValue.forEach((item) => {
      remove(localesArray, (i) => i === item.locale);
    });
    return localesArray;
  }, [field, field.value]);

  function changeValue(index: number, value: string) {
    const newValue = [...localesValue];
    newValue[index].value = value;

    field.onChange(newValue);
  }

  function changeLocale(locale: string, index: number) {
    const newValue = [...localesValue];
    newValue[index].locale = locale;
    field.onChange(newValue);
  }

  function add() {
    const newValue = [...localesValue, { locale: leftTypes[0], value: "" }];
    field.onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...localesValue];
    remove(newValue, (item, i) => i === index);
    field.onChange(newValue);
  }

  return (
    <Box p={"8px 0"} width={"100%"}>
      <InputLabel>{label}</InputLabel>
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        <TabContext value={selectTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TabList onChange={handleTableChange}>
              {localesValue.map((item, index) => {
                return (
                  <Tab
                    value={index + ""}
                    key={index + ""}
                    label={
                      <CustomLabel
                        label={
                          <LocalesSelect
                            value={item.locale}
                            locales={[item.locale, ...leftTypes]}
                            onChange={(locale) => changeLocale(locale, index)}
                          ></LocalesSelect>
                        }
                        onClick={() => removeByIndex(index)}
                      />
                    }
                  />
                );
              })}
            </TabList>
            {leftTypes.length > 0 && (
              <Button
                size={"medium"}
                onClick={() => add()}
                startIcon={<AddCircleIcon />}
                label={"添加"}
              />
            )}
          </Box>

          {localesValue.map((item, index) => {
            return (
              <TabPanel
                value={index + ""}
                key={index}
                sx={{ maxWidth: "100%" }}
              >
                <RichTextInput
                  onChange={(e) => changeValue(index, e)}
                  value={item.value}
                />
                <Accordion variant="outlined">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>html</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OutlinedInput
                      multiline={true}
                      fullWidth={true}
                      rows={18}
                      value={item.value}
                      onChange={(e) => changeValue(index, e.target.value)}
                    />
                  </AccordionDetails>
                </Accordion>
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </Box>
  );
}

const CustomLabel = ({
  label,
  onClick,
}: {
  onClick: () => void;
  label: React.ReactElement;
}) => (
  <Box display="flex" alignItems="center">
    <Box>{label}</Box>
    <Close
      fontSize="small"
      sx={{ cursor: "pointer", marginLeft: 0.5 }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    />
  </Box>
);

function LocalesSelect(props: {
  value: string;
  locales: string[];
  onChange: (e: string) => void;
}) {
  const { value, locales, onChange } = props;
  console.log("LocalesSelect", value, locales);
  return (
    <Select<string>
      variant={"standard"}
      disableUnderline={true}
      value={value}
      size={"small"}
      onChange={(e) => onChange(e.target.value)}
    >
      {locales.map((item, i) => {
        return (
          <MenuItem value={item} key={i}>
            {toLocaleName(item as LocalesType)}
          </MenuItem>
        );
      })}
    </Select>
  );
}
