import { MyField } from "../../components/types";
import { ArrayInput, FunctionField } from "react-admin";
import { base, BaseArgs } from "./base";
import React, { ReactNode } from "react";

export interface IteratorArgs extends BaseArgs {
  children: ReactNode;
}

const defaultArgs: Partial<IteratorArgs> = {
  isRequired: false,
  fullWidth: false,
  isFilter: false,
};

export function iterator(args: IteratorArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FunctionField,
      props: {
        source: args.source,
        render: (r: any) => {
          return `${r?.[args.source].length}`;
        },
      },
      editComponent: ArrayInput,
      editProps: {
        children: args.children,
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
