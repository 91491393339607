import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const GroupApi = {
  mute: async (groupID: string): Promise<void> => {
    await fetchJson(`${API_URL}/groups/mute/${groupID}`, {
      method: "POST",
    });
  },
  unmute: async (groupID: string): Promise<void> => {
    await fetchJson(`${API_URL}/groups/unmute/${groupID}`, {
      method: "POST",
    });
  },
};
