export const GroupRedStatusChoices = [
  {
    id: "INIT",
    name: "未领取",
  },
  {
    id: "FINISH",
    name: "完成",
  },
  {
    id: "PART_FINISH",
    name: "部分完成",
  },
  {
    id: "PART_FINISH_REFUND",
    name: "部分完成,已退",
  },
];
export const GroupRedRecordStatusChoices = [
  {
    id: "UNRECEIVED",
    name: "未领取",
  },
  {
    id: "RECEIVED",
    name: "已领取",
  },
  {
    id: "EXPIRED",
    name: "过期已退",
  },
];
