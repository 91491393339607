import { convertFileToBase64 } from "./fileToBase64";
import omit from "lodash/omit";

export const transform = async (data: any) => {
  if (data?.key?.rawFile) {
    const key = await convertFileToBase64(data.key.rawFile);
    return { ...data, key };
  }
  if (data.key && data.key.src) {
    return { ...data, key: data.key.src };
  }
  return { ...omit(data, ["key"]), key: "" };
};
