import { buildField } from "../Field";
import { required } from "react-admin";
import { MyField } from "../../components/types";

export interface BaseArgs {
  label: string;
  source: string;
  isRequired?: boolean;
  isFilter?: boolean;
  isCreate?: boolean;
  /**
   * 是否允许修改
   */
  isEdit?: boolean;
  /**
   * 修改的时候是否允许编辑
   * 如果 isEdit = true, 那么这个属性表示是否可以编辑!
   */
  isUpdate?: boolean;
  isList?: boolean;
  isShow?: boolean;
  fullWidth?: boolean;
  minWidth?: number;
  maxWidth?: number;
  sortable?: boolean;
  helperText?: string;
  defaultValue?: any;
}

export function base(args: BaseArgs, fieldSrc: MyField, field?: MyField) {
  const {
    label,
    source,
    isRequired = true,
    isFilter = true,
    isUpdate = true,
    isEdit = true,
    isCreate = true,
    isList = true,
    isShow = true,
    fullWidth = false,
    sortable = false,
    minWidth,
    maxWidth,
    helperText,
  } = args;

  return buildField(
    {
      label,
      source,
      //
      isFilter,
      isUpdate,
      isEdit,
      isCreate,
      isList,
      fullWidth,
      isShow,
      minWidth,
      maxWidth,
      sortable,
      ...fieldSrc,
      editProps: {
        validate: isRequired ? [required()] : [],
        fullWidth,
        helperText,
        defaultValue: args.defaultValue,
        ...fieldSrc.editProps,
      },
      filterProps: {
        ...fieldSrc.filterProps,
      },
    },
    field
  );
}
